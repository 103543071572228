/* .App-header {
  background-image: linear-gradient(to right, white, #bad4a6);
  min-height: 8vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */
img.logo {
  height: 6vh;
  display: inline-block;
  margin: 1vh
}
